import React, { useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'
//import { Web3Provider } from '@ethersproject/providers'
import App from '../App';

import Web3 from 'web3';

import { injected, walletConnect} from './Web3Connectors';


// styles
import classes from '../App.module.css';


export const Web3Wallet = ( props ) => {

  console.log("web3wallet props:",props);

  const { chainId, account, activate, active,library } = useWeb3React()

  console.log("web3wallet LIB:",library);

  const enableEthereum = () => {
    console.log("web3wallet: activation staged");
    
    window.ether    = window.ethereum;

    if (typeof window.phantom !== "undefined" && typeof window.phantom.ethereum !== "undefined") {
      window.ether    = window.phantom.ethereum;
    }

    window.web3 = new Web3(window.ether);
    window.account = account;

  }

  const onClickM = async () => {
    await activate(injected)
    enableEthereum();
  }
  const onClickWC2 = async () => {
    await activate(walletConnect)
    enableEthereum();
  }

  useEffect(() => {
    console.log(chainId, account, active)
    },);

  return (
    <div>
      <App 
        connectMetaMask={onClickM}
        connectWalletConnect={onClickWC2}

      />
      <div className={classes.topframe}      >
        {active ? (
          <div>
          </div>
        ) : (
          <div>
          </div>
        )}
      </div>
     </div>
  )
}

/*
            <div>ChainId: {chainId} | Account: {account}</div>

            <button type="button" onClick={onClickM}>
Metamask
</button>
<button type="button" onClick={onClickWC2}>
WalletConnect
</button>
*/
