import React, { useState } from 'react';

import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import digIcon2 from '../assets/dig2.png';
//import mineIcon from '../assets/mine500.png';
//import rightIcon from '../assets/rightIcon.png';

import { xBalance } from '../X';




const Block = (props) => {
  const [inputValue, setInputValue] = useState('');
/*'en-US'*/

  //const getLocale =  () => { return 'en-US'; }

  /*
  const getLocale =  () => { return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language; }
  */
  const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
  const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }

  const inputChangeHandler = (event) => {

    event.preventDefault();
//console.log(event.target.value);
    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      setInputValue(i);

  //    console.log(i,f);

      props.inputHandlerBlock(f *  10**props.info.decimals);
      props.inputHandlerUnBlock(f *  10**props.info.decimals);
    } else {
      setInputValue("");
    }
  };

  const goMax = () => {

    console.log( props.balances[xBalance.erc20], 10**props.info.decimals);
    setInputValue( props.balances[xBalance.erc20] / 10**props.info.decimals);

    props.inputHandlerBlock( parseInt(props.balances[xBalance.erc20]));
    props.inputHandlerUnBlock( parseInt(props.balances[xBalance.erc20]));
//    props.inputHandler(props.balances[xBalance.erc20] / 10**props.info.decimals);
//    props.inputHandler(props.userBalance);
  };


  return (
   <div className={classes.infologo}>
    <div className={classes.Info}>
      <div style={{ display: "flex", "padding-left": "10%" }}>
          <button
            className={classes.checkButton}
            onClick={() => {
                props.protectHandler();
            }}
            >
            <p>{ props.info.isProtected ?  "Unseal" : "Seal" }</p>
          </button>
      </div>


      <div className={classes.go} >
         <b>{props.balances ? rcRock(props.balances[xBalance.erc20]) : "n/a"}</b> R0CK
          <br/>
          <span>VISIBLE</span>
        </div>

        <p><br/></p>

      <div className={classes.inputDiv}>
        <input
          placeholder="Amount of Coins"
          className={classes.input}
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
        &nbsp;
        <button
          className={classes.allButton}
           onClick={goMax}
        >
          <p>All</p>
        </button>
      </div>

      <button
        className={classes.blockButton}
        disabled={ !props.balances || props.balances[xBalance.erc20] < 1 ||   inputValue > props.balances[xBalance.erc20] / 10**props.info.decimals }
        onClick={() => {
          props.actionHandlerBlock();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ inputValue === "" ? "Enter amount" : ( inputValue > props.balances[xBalance.erc20] / 10**props.info.decimals ? "too much" : "Store") }</p>
      </button>

      &nbsp;

      <button
        className={classes.blockButton}
        disabled={ !props.balances || ( props.balances[xBalance.blocked] < 1 && !props.info.isProtected) ||   ( inputValue > props.balances[xBalance.blocked] / 10**props.info.decimals  && !props.info.isProtected) }
        onClick={() => {
          props.actionHandlerUnBlock();
          setInputValue('');
        }}
      >
        <img src={digIcon2} alt="dig icon" className={classes.digIcon} />
        <p>{  inputValue === "" ? "Enter amount" : ( inputValue > props.balances[xBalance.blocked] / 10**props.info.decimals ? "too much" : "Unstore")  }</p>
      </button>


      <p><br/></p>
      <div className={classes.gor} >
        <p><b>{props.balances ? ( props.info.isProtected ? "Some" : rcRock(props.balances[xBalance.blocked])) : "n/a" }</b> R0CK
        <br/>
        <span>VAULTED</span>
        </p>        
      </div>

      <p><br/></p>


    </div>
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Block;
