import React from "react";

import coinAbi from './abis/R0CK.json';
import toolsAbi from './abis/ROCK3TOOLS.json' ;

import coinIcon from './assets/coin/r0ck500.png';
import coinIcon128 from "./assets/coin/r0ck128.png";

import headerIcon from './assets/coin/r0ckheader.png'; //128.png';


export const z = {
  title: "R0CK",
  ccTitle: "R0CK 3TH",
  fullTitle: "",
  coin: "R0CK",

  icon: coinIcon,
  icon128: coinIcon128,
  headerIcon: headerIcon,

  abi: coinAbi,

  erc20icon: "https://rockcc.app/info/r0ck500.png",

  stripeURI: "https://buy.stripe.com/9AQeXt4572Iz5568wM",

  portalURI: 'https://rockcc.app/?origin',


  hint: "",
  _hint: "( 1 Sqare RET represents 1m² = 2.000 Skyland CC)",

  contractMap: {
    1: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    4: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    5: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
    11155111: { shield: coinAbi, tools: toolsAbi,  alternate: coinAbi },
  },

  orderForm: {
    coinName: "Rock ETH"
  },

  adviceBoxDig:"",

  _adviceBoxDig:
      <p>R0CK is a autonomous stable coin. it wraps 0.98 ETH per Coin<br/></p>,

}


/*  styles left:

.header {
  background-image: url("../assets/coin/iconHeader.png");
}

.logo {
  background-image: url("../assets/coin/icon1500x.png");
}
*/


