


//import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
//import { initializeConnector } from '@web3-react/core'

//import { WalletConnect  as WalletConnect2Connector } from "@web3-react/walletconnect-v2";
//import { EthereumProvider } from '@walletconnect/ethereum-provider';
//import { walletConnect as WalletConnect2Connector } from 'wagmi/connectors';




import { Web3ReactHooks } from '@web3-react/core'


import { chains, supportedChainIds, supportedNetworkURLs } from '../X';
/*
export const coinbaseWallet = new WalletLinkConnector({
 url: `https://mainnet.infura.io/v3/v3/4bd1467d1bb14c04897dc22d72bdf57d`,
 appName: "RWI Rock Wallet Interface",
 supportedChainIds: supportedChainIds,
});
*/


export const walletConnect = new WalletConnectConnector({
/*
  supportedNetworkURLs: `https://mainnet.infura.io/v3/v3/4bd1467d1bb14c04897dc22d72bdf57d`,
 bridge: "https://bridge.walletconnect.org",
 qrcode: true,
*/

 projectId: '3fcc6bba6f1de962d911bb5b5c3dba68',
 metadata: {
   name: 'Skyland CC',
   description: 'My Website Description',
   url: 'https://skylandcc.app', // origin must match your domain & subdomain
   icons: ['https://skylandcc.app/info/skyland500.png']
 },
 showQrModal: true,
 optionalChains: [1, 137, 2020],
 
});

/*
// wagmi
export const walletConnect2 = new WalletConnect2Connector({
  projectId: '3fcc6bba6f1de962d911bb5b5c3dba68',
  metadata: { 
    name: 'Example', 
    description: 'Example website', 
    url: 'https://example.com', 
  }, 
})
*/
/*
export const walletConnect2 = new WalletConnect2Connector({
  projectId: '546abe5bcf9860bf15da09893ecd03eb',
  rpcMap: supportedNetworkURLs,

  name: 'Skyland CC',
  description: 'Skyland CC',
  url: 'https://skylandcc.app', // origin must match your domain & subdomain

  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
 });
*/

/*
export const walletConnect2 = new WalletConnectConnector({

  rpc: { 
    1: `https://mainnet.infura.io/v3/v3/4bd1467d1bb14c04897dc22d72bdf57d`
  },
  bridge: 'https://bridge.walletconnect.org', // Optional bridge URL
  qrcode: true,
  projectId: '546abe5bcf9860bf15da09893ecd03eb', // WalletConnect v2 requires a project ID
  relayUrl: 'wss://relay.walletconnect.com', // Required for WalletConnect v2
  metadata: {
    name: 'Skyland CC',
    description: 'Skyland CC',
    url: 'https://skylandcc.app', // origin must match your domain & subdomain
    icons: ['https://skylandcc.app/info/skyland500.png'],
  },
  pollingInterval: 12000,
});
*/

export const injected = new InjectedConnector({
 supportedChainIds: supportedChainIds
});

