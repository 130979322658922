import React, { useState } from 'react';

import classes from './Profile.module.css';
import digIcon from '../assets/dig.png';
import digIcon2 from '../assets/dig2.png';
//import mineIcon from '../assets/mine500.png';
//import rightIcon from '../assets/rightIcon.png';

import { xBalance } from '../X';


import { z } from '../Z';
import { xNullAddress, xUSDTAddress, xUSDZAddress, xTrader } from "../X";


const Wrap = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [inputCount, setInputCount] = useState('');

  const [netCost, setNetCost] = useState(0);
  const [netReserve, setNetReserve] = useState(0.05 * 10**18);

  /*'en-US'*/

  //const getLocale =  () => { return 'en-US'; }

  /*
  const getLocale =  () => { return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language; }
  */
  const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
  const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }


  const pC = window.Currency;
  const minDigDecimals = props.info.decimals < 4 ? props.info.decimals : 3;



  const addToWallet = ( ) => {

    let web3 = window.web3;
 
    web3.eth.currentProvider.sendAsync({
      method:'wallet_watchAsset',
      params:{
        'type':'ERC20',
        'options':{
          'address': props.contract._address,
          'symbol': props.info.symbol,
          'decimals':props.info.decimals,
          'image': z.erc20icon
        },
     },
     id:Math.round(Math.random()*100000)
    }, function(err,data){
      if(!err) {
        if(data.result){
           console.log('Token added');
         }else{
           console.log(data);
           console.log('Some error');
         }
       } else {
         console.log(err.message);
       }
     });
   /*
   }catch(e){console.log(e);}});return false;}else{try{web3obj.eth.currentProvider.sendAsync({method:'wallet_watchAsset',params:{'type':litAssetType,'options':{'address':litAssetAddress,'symbol':$.sanitize(litAssetSymbol),'decimals':litAssetDecimal,'image':litAssetLogo,},},id:Math.round(Math.random()*100000)},function(err,data){if(!err){if(data.result){console.log('Token added');}else{console.log(data);console.log('Some error');}}else{console.log(err.message);}});}catch(e){console.log(e);}}});}
 */
 
  }
 
  const inputChangeHandler = (event) => {

    event.preventDefault();
//console.log(event.target.value);
    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);

      setInputValue(i);

  //    console.log(i,f);

      props.inputWrap(f );
      props.inputUnwrap(f );
    } else {
      setInputValue("");
    }
  };




  const getUpCost = () => {
    const plus = 10; // %

    let gasLimit = 100000; //"0x1D4C0";

    /*
      WARNING: THIS INCLUDES MUCH OVERHEAD TO PREVENT OUT OF BALANCE
    */
    const upGasPrice = props.gasPrice > 40 ? parseInt(props.gasPrice) * 1.5 : (
            props.gasPrice > 20 * 10**9 ? parseInt(props.gasPrice) * 1.7 :
              parseInt(props.gasPrice) * 2
          );

    const upCosts = (parseInt(upGasPrice) + 10*10**9) * gasLimit;

//    console.log("getmax",props.gasPrice,gas, plus, costs, props.userBalance,  props.priceDecimals, props.price, pC,digEst);

    setNetCost(upCosts);
    console.log("costs: gas ",upCosts,upGasPrice,gasLimit);

    return upCosts;
  }



  const getMaxCount = () => {

    const upCosts = getUpCost();

    // FIXME: if ethereum, add costs !!
    if (pC === xNullAddress ) {

      let mx = props.userBalance * 10**18 - upCosts - netReserve / props.price;

      console.log( "cntmax",mx   );
      return Math.floor(  mx );
    }
    console.log( "cntmax token");

    let mx = props.userBalance * 10**props.priceDecimals / props.price;
    return Math.floor(  mx );

  }

  const getMaxValue = () => {

    const upCosts = getUpCost();
    
    // FIXME: if ethereum, add costs !!
    if (pC === xNullAddress ) {

      let mx = props.userBalance * 10**18 - upCosts - netReserve;

      console.log( "clcmax",mx   );
      return Math.floor(  mx     / 10**(18-3) ) / 10**3; /// 10**props.priceDecimals) * (10**6 )));
    }
    console.log( "clcmax token");

    let mx = props.userBalance * 10**props.priceDecimals;

    return Math.floor( mx ) / 10**props.priceDecimals; // * (10**6 )));

  }

  const goMaximum = () => {

    let i = getMaxValue();
    console.log("get max",i);

    if (i <= 0.001) {
       i = 0;
       setInputValue("");
     } else{
       setInputValue(""+i);
     }
console.log(props.wrapFee);
     let cnt = Math.floor( 10**minDigDecimals * (i/props.wrapFee) / (props.price / 10**props.priceDecimals) ) / 10**minDigDecimals;
     console.log(i,"=>",cnt);
     setInputCount(cnt);

     props.inputWrap( parseInt(props.balances[xBalance.erc20]*100)/100);
 
  };


  const priceRound = () => {  return Math.round( 100000*( props.price / 10**props.priceDecimals) )/100000  }


  return (
   <div className={classes.infologo}>
   <div >
     <p><br/><br/></p>
   </div>
    <div className={classes.Info}>


      <div className={classes.gox} >
        <b>{ Math.floor(props.userBalance * 1000)/1000 } </b> {props.priceSymbol}
        <br/>
          <span>YOUR BALANCE</span>
        </div>
        <p><br/></p>


      <div className={classes.inputDiv}>
        <input
          placeholder="Amount of R0CK"
          className={classes.input}
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
        &nbsp;
        <button
          className={classes.allButton}
           onClick={goMaximum}
        >
          <p>All</p>
        </button>
      </div>

      <button
        className={classes.blockButton}
        _disabled={ !props.balances || props.balances[xBalance.erc20] < 1 ||   inputValue > props.balances[xBalance.erc20] / 10**props.info.decimals }
        onClick={() => {
          props.actionWrap();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ inputValue === "" ? "Enter amount" : ( inputValue > 0 ? "Wrap" : "...") }</p>
      </button>

      &nbsp;

      <button
        className={classes.blockButton}
        _disabled={ !props.balances || ( props.balances[xBalance.blocked] < 1 && !props.info.isProtected) ||   ( inputValue > props.balances[xBalance.blocked] / 10**props.info.decimals  && !props.info.isProtected) }
        onClick={() => {
          props.actionUnwrap();
          setInputValue('');
        }}
      >
        <img src={digIcon2} alt="dig icon" className={classes.digIcon} />
        <p>{ inputValue === "" ? "Enter amount" : ( inputValue > 0 ? "Unwrap" : "...") }</p>
      </button>


      <p><br/></p>
      <div className={classes.go} >
        <p> <b>{props.balances ? ( props.info.isProtected ? "Some" : rcRock(props.balances[xBalance.erc20])) : "n/a" }</b> R0CK
        <br/>
          <span>YOUR COINS</span>
          </p>
          </div>

      <p><br/></p>

      <div className={classes.priceInfoBox}>
        <p>WRAP RATE: <b>{ priceRound() + " " + props.priceSymbol + " / " + z.title}</b></p>
        <p>WRAP ONE-TIME-FEE: <b>{ props.wrapFee / 10 + "% " }</b>per R0CK</p>
      </div>
 

      <p><br/></p>
        <p>Add Token to your Wallet:
        <button
          className={classes.importButton}
          onClick={addToWallet}
        >
          <p>Import</p>
        </button>
        </p>
        <p><br/></p>
        <p><br/></p>
  
    </div>
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Wrap;
