
import React from 'react';
import ReactDOM from 'react-dom';

/*
import Web3Provider from 'web3-react'
import { Web3Wallet } from './web3/Web3Wallet';
import { connectors } from './web3/Web3Connectors';
*/


import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Web3Wallet } from './web3_v6/Web3Wallet';
//import { connectors } from './web3_v6/Web3Connectors';


import { ConnectButton } from './components/ConnectButton';

import './index.css';

//v6
function getLibrary(provider: any): Web3Provider {
      const library = new Web3Provider(provider)
      library.pollingInterval = 12000
      return library
}

//v8
//connectors={connectors}   >

/*
ReactDOM.render(
      <div>
            <ConnectButton/>
      </div>
, document.getElementById('root'));
*/

/*
ReactDOM.render(
      <Modal></Modal>
, document.getElementById('root'));
*/

ReactDOM.render(
      <div>
          <Web3ReactProvider 
            getLibrary={getLibrary}
            >
              <Web3Wallet>
              </Web3Wallet>
      </Web3ReactProvider>
      </div>
, document.getElementById('root'));



/*

ReactDOM.render(
                <Web3Provider
                                connectors={connectors}
                                libraryName={'ethers.js'}
                                >
                        <Web3Wallet>
                              <App />
                        </Web3Wallet>
                </Web3Provider>
, document.getElementById('root'));
*/

