import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";

import classes from "./Profile.module.css";
import copyicon from "../assets/copymini.png";

//import PreSale from "./PreSale";

import { z } from '../Z';
import { xBalance } from "../X";

import ConnectW from "./Connect";

const Profile = (props) => {
  const [copiedInfo, setCopiedInfo] = useState(false);
  //  const [copiedInfo2, setCopiedInfo2] = useState(false);

  let uriInviteDotted = props.uriInvite.substring(0, 20) + "...";
  let uriInviteDeepLink = [
    "https://metamask.app.link/dapp",
    props.uriInvite.replace("https://", ""),
  ].join("/");

  const copyAddress = (event) => {
    navigator.clipboard.writeText(props.uriInvite);

    setCopiedInfo(true);

    setTimeout(() => {
      setCopiedInfo(false);
    }, 3000);
  };
  /*
    const copyAddress2 = (event) => {
       navigator.clipboard.writeText(props.contract._address);

       setCopiedInfo2(true);

       setTimeout(() => {
         setCopiedInfo2(false);
      }, 3000);
    };
*/

  /*
    const closeBox = (event) => {
       setCopiedInfo(false);
    };

    <li> total: <b>{props.balances ? parseInt(props.balances[xBalance.erc20]) + parseInt(props.balances[xBalance.blocked]): "n/a"}</b> ROCKs</li>
    <li> rewarded: <b>{props.balances ? props.balances[2] : "n/a" }</b> Pebbles</li>

*/

  /*
    const datum = (s) => {
           let dt = new Date(s * 1000);
           return (
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
      }
*/

  const rc = (p, d, c) => {
    let a = p / 10 ** d;
    let i = Math.floor(a);
    let f = a - i;
    return i.toLocaleString("en-US") + f.toFixed(c).toString().substring(1);
  };
  const rcRock = (p) => {
    return rc(p, props.info.decimals, 3).replace(/0+$/, "").replace(/\.$/, "");
  };

  const rcPrice = (p) => {
    return rc(p, props.priceDecimals, 2);
  };
  const rcPrice6 = (p) => {
    return rc(p, props.provDecimals, 2);
  };

  const rcPriceX = (p) => {
    return rc(p, props.rpriceDecimals, 8).replace(/0+$/, "").replace(/\.$/, "");
  };

  //      <div  style={{  position: "relative", left: "0%"}}><b>{props.balances ? rcPriceX(props.balances[xBalance.summarizedTotalAPY]) : "n/a" }</b> {props.rpriceSymbol}<br/><b style={{"font-size": "0.5em"}}>EARNED</b></div>
  /*
      <PreSale
            preSaleDate={props.preSaleDate}
            preSaleOffer={props.preSaleOffer}
            preSaleTotal={props.preSaleTotal}
            preSaleLimit={props.preSaleLimit}
    />
*/

  return (
    <div className={classes.profile}>
      <br></br>
      {props.isOnline() ? (
        <div className={classes.logo} width="200">
          <div className={classes.xx}>
            <div style={{ position: "relative", top: "0em" }}>
              <b>
                {props.balances
                  ? rcRock(
                      props.balances[xBalance.erc20] * 1 ,
                    )
                  : "n/a"}
              </b>{" "}
              {z.title}
              <br />
              <b style={{ "font-size": "0.5em" }}> PUBLIC</b>
            </div>
            <div style={{ width: "50%" }}></div>
            <div style={{ position: "relative", top: "0em" }}>
              <b>

              {props.balances ? ( props.info.isProtected ? "Some" : rcRock(props.balances[xBalance.blocked])) : "n/a" } R0CK
              </b>{" "}
              
              <b style={{ "font-size": "0.5em" }}><br/> VAULTED</b>
            </div>
          </div>
        </div>
      ) : props.wasWalletEnabled() ? (
        <div className={classes.header}>
          <p>
            <br />
          </p>
        </div>
      ) : (
        <div>
          <ConnectW
            account={props.account}
            network={props.network}
            uriParts={props.uriParts}
            connectMetaMask={props.connectMetaMask}
            connectWalletConnect={props.connectWalletConnect}
          />
        </div>
      )}

      <br />
    </div>
  );
};

export default Profile;

/*
&nbsp;
<button
className={classes.connectButton}
  onClick={() => {
    props.connectWalletConnect();
  }}>
  Walletconnect
</button>

                    <br/> invite deep uri:
                    <br/><b>{uriInviteDeepLink}</b> <b><a href={uriInviteDeepLink}  style={{  color: "white"}}>Go</a></b>
                        &nbsp;
                    <br/>

*/
