import React, { useState } from 'react';

import classes from './Profile.module.css';
//import classesShake from './Shake.css';

//import phantomIcon from '../assets/phantom.svg';
//import braveIcon from '../assets/brave.svg';
import metamaskIcon from '../assets/metamask.svg';
import coinbaseIcon from '../assets/coinbase.svg';
//import trustIcon from '../assets/trust.svg';
import etherIcon from '../assets/ether.png';

import orderIcon from '../assets/contractsigned.png';
import cardIcon from '../assets/cardIcon.png';
import cryptoIcon from '../assets/cryptoIcon.png';


import { QRCode } from 'react-qr-code';
import { isMobile } from 'react-device-detect';
import { z } from '../Z';


const ConnectW = (props) => {

    console.log(props);

    let uri  = props.uriParts.join("/");
    let domain = "https://" + props.uriParts[2];
 
    let uri3 = uri; 
    if (props.uriParts.length < 5) {
        uri += "?"            
    }
    uri  += "/enabled";
    let uri2 = uri.replace('?','deep');
    console.log("ooo",props.uriParts,uri);    


    const openInNewTab = url => {
      window.open(url, '_blank', 'noopener,noreferrer');
    };
  
    const connectWallet = () => {
      props.connectMetaMask(); 
    }
    const connectWallet2 = () => {
      props.connectWalletConnect(); 
    }
  
    const goToOrderForm = () => {
      //props.connectWalletConnect(); 
    }
    const goToStripe = () => {
      //props.connectWalletConnect(); 
    }
  
    const wallets = [
        {      
          "title": "Builtin Wallet",
          "icon": etherIcon,
          "type": "#",
          "handler": connectWallet
        },
       {
          "title": "Metamask",
          "icon": metamaskIcon,
          "type": "C",
          "uri": ["https://metamask.app.link/dapp", uri2.replace("https://","") ].join("/")
        },{      
          "title": "Coinbase",
          "icon": coinbaseIcon,
          "type": "C",
          "uri": 'https://go.cb-w.com/dapp?cb_url=' + encodeURI(uri2)
        }
      ]
      /*
      ,{
          "title": "or order the classic way:",
          "type": "X"
        },{        
          "title": "OrderForm",
          "icon": orderIcon,
          "type": "C",
          "uri": "/order/",
          "handler": goToOrderForm
        },{      
          "title": "CreditCard",
          "icon": cardIcon,
          "type": "C",
          "_uri": "https://sqares.tech/",
          "uri": z.stripeURI,
          "handler": goToStripe
        },{      
          "title": "Crypto Payment",
          "icon": cryptoIcon,
          "type": "C",
          "uri": z.paymentURI
        }

    ]
      */
     
    const wallets2 = [
    ]
    /*
      {      
        "title": "or order the classic way:",
        "type": "X"
      },{        
        "title": "OrderForm",
        "icon": orderIcon,
        "type": "C",
        "uri": "/order/",
        "handler": goToOrderForm
      },{      
        "title": "CreditCard",
        "icon": cardIcon,
        "type": "C",
        "_uri": "https://sqares.tech/",
        "uri": z.stripeURI,
        "handler": goToStripe
      },{      
        "title": "Crypto Payment",
        "icon": cryptoIcon,
        "type": "C",
        "uri": z.paymentURI
      }
*/
  
/*
        },{      
          "title": "Phantom",
          "icon": phantomIcon,
          "uri": 'https://phantom.app/ul/browse/' + encodeURIComponent(uri2) + '?ref=' + encodeURIComponent(domain),
          "_uri": 'phantom://ul/browse/' + encodeURI(uri2) + '?ref=' + encodeURI(domain)
        },{      
          "title": "Trust",
          "icon": trustIcon,
          "_uri": 'https://link.trustwallet.com/open_url?url=' + encodeURIComponent(uri2),
          "uri": 'https://link.trustwallet.com/open_url?&event=openURL&url=' +  encodeURIComponent(uri2)
*/
    console.log("###########",props.uriInvite,wallets);


  const WalletButton = (props) => {
    return (
      <button>                    
      <div className="connectlogo">
        <img alt={props.wallet.title} src={props.wallet.icon}
      />
      </div>
      <div className="connecttitle">
        {props.wallet.title}
      </div>
    </button>
    )
  }

/*
  <a onClick={connectWallet2}
  target="_blank" rel="noreferrer noopener">
  <WalletButton wallet={wallets[wallets.length-1]}/>
  </a>
*/
  return (
    <div className={classes.profile}>
      <br></br>
      <div>
        <div className={classes.warning}>
          <p>Connect your Wallet!</p>
        </div>
        { !isMobile ? (
          <div className={classes.connect}>
            <a onClick={connectWallet}>
                <WalletButton wallet={wallets[0]}/>
            </a>
            <br/>  

            <br/>  
            <p>click button above for Browser <br/>or scan QR code with your mobile!</p>
            <br/>
            <QRCode
                          value={uri3}
                          eyeRadius={6}
                          qrStyle="fluid" 
                          __qrStyle="squares" 
                          ecLevel="M" size="200"
                          fgColor="rgb(253, 85, 36)"
                          _fgColor="rgb(135, 238, 251)"
                            bgColor="rgb(0 0 0)"
                          _logoImage={ z.icon128 } _logoWidth="50"
                          _removeQrCodeBehindLogo={true}
                          _onClick={
                            ()=>{
                              openInNewTab(uri);
                            }
                          }
              />

          </div>
        ):(
          <div className={classes.connect}>
            {wallets.map(( wallet, index) => {
            
              return (
                <div>                
                  {             
                    { 
                      '#':
                          <a onClick={wallet.handler}
                            target="_blank" rel="noreferrer noopener">
                            <WalletButton wallet={wallet}/>
                          </a>,
                      'X' :
                          <div className={classes.warning}>
                            <p><br/>{wallet.title}</p>
                          </div>,
                      'C' :
                          <a href={wallet.uri}  target="_blank" rel="noreferrer noopener">
                            <WalletButton wallet={wallet}/>
                          </a>
                    }[wallet.type]
                  }
                </div>
              )
            })}
          </div>
        )}
      </div>
                  
      <p><br/><br/><br/></p>
    </div>
  );
};

export default ConnectW;


/*
              <div className={classes.connect}>
              {wallets2.map(( wallet, index) => {
              
                return (
                  <div>                
                    {             
                      { 
                        '#':
                            <a onClick={wallet.handler}
                              target="_blank" rel="noreferrer noopener">
                              <WalletButton wallet={wallet}/>
                            </a>,
                        'X' :
                            <div className={classes.warning}>
                              <p><br/>{wallet.title}</p>
                            </div>,
                        'C' :
                            <a href={wallet.uri}  target="_blank" rel="noreferrer noopener">
                              <WalletButton wallet={wallet}/>
                            </a>
                      }[wallet.type]
                    }
                  </div>
                )
              })}
            </div>

            */