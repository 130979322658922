import React, { Component } from "react";
import PropTypes from "prop-types";

//import rockIcon from '../assets/ares128.png';
import nfoIcon from '../assets/infoMini.png';
import orderIcon from '../assets/contractsigned.png';
import coinIcon from '../assets/balance.png';
import digIcon from '../assets/digicon.png';
import cardIcon from '../assets/cardIcon.png';



class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  rswitch = (param, cases) => {
    if (cases[param]) {
      return cases[param]
    } else {
      return cases.default
    }
  }

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    return (
      <li className={className} onClick={onClick}>
        {
          this.rswitch(label, {
            "*": <img alt="Info" src={nfoIcon} height="24px"/> ,
            "Order": <img alt="Order" src={orderIcon} height="24px"/> ,
            "Card": <img alt="Card" src={cardIcon} height="24px"/> ,
            "Coin": <img alt="Coin" src={coinIcon} height="24px"/> ,
            "Dig": <img alt="Dig" src={digIcon} height="24px"/> ,
            default: label
          })
         }
      </li>
    );

  }
}

export default Tab;
